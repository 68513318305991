<template>
  <section>
    <AddPost/>
  </section>
</template>

<script>
import AddPost from "@/components/dashboard/AddPost"
export default {
  name: "AddAd",
  components: {AddPost },
  data: () => ({
    headercontent: {
      title: "Add Ad",
      addlink: "/dashboard/blog/add",
      downloadlink: true,
    },
  }),
  methods: {    
  },
  computed: {
    
  },

};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  width: 200px;
  min-height: 50px;
}

.imagebox {
  display: flex;
  max-width: 30%; 
    // background: rgb(248, 248, 248);
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px;
  margin-top: 20px;
  label {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
